export default {
    "О полистиролбетоне": "Polystyrene concrete",
    "Услуги": "Services",
    "Продукция": "Products",
    "Контакты": "Contacts",
    "О нас": "About us",
    "Портфолио": "Portfolio",
    "Доставка": "Delivery",
    "ХОТИТЕ ЗАДАТЬ ВОПРОС ": "WOULD YOU LIKE TO ASK A QUESTION",
    "ИЛИ ЗАКАЗАТЬ МАТЕРИАЛЫ?": "OR PLACE AN ORDER FOR MATERIALS",
    "Наши специалисты будут рады проконсультировать, помочь с выбором и расчетом материала под ваши задачи.": "Our specialists will be happy to advise, help with the selection and calculation of materials for your needs.",
    "Отправить заявку": "Send a request",
    "Адрес : РА, Араратский марз, с. Арарат, Арарат, 5 участок Ереванского шоссе.": "Address: Yerevanyan Highway, plot 5, v. Ararat, Ararat, Ararat Province, RA.",
    "Номер : TestPhoneNumber": "Phone number",
    "ПРОИЗВОДИМ ПРОДУКЦИЮ": "WE MANUFACTURE PRODUCTS",
    "ДЛЯ СТРОИТЕЛЬСТВА": "FOR CONSTRUCTION",
    "стеновые Блоки": "wall Blocks",
    "Вентиляционные Блоки": "Ventilation Blocks",
    "Армированные перемычки": "Reinforced lintels",
    "Фасадные блоки": "Reinforced lintels",
    "Теплоизоляционные плитки": "Thermal insulation tiles",
    "сухие строительные смеси": "dry building mixtures",
    "о нАС": "About us",
    "ООО «М1 GROUPE» — производственно-строительная компания полного цикла, производитель полистиролбетона.": "М1 GROUPE' LLC is a full-cycle production and construction company, a manufacturer of polystyrene concrete.",
    "В компании работает команда профессионалов с многолетним опытом в отрасли. Наша цель — лидирующие позиции в производстве, продвижении и реализации инновационных строительных материалов из полистиролбетона. Среди наших заказчиков — как частные застройщики, так и крупные строительные организации.": "The company has a team of professionals with many years of experience in the industry. Our goal is a leading position in the production, promotion and sale of innovative building materials made from polystyrene concrete. Our customers include both private developers and large construction organizations.",
    "Мы любим свою работу и отвечаем за качество всех услуг и продукции, которые получают наши клиенты.": "We love our work and are responsible for the quality of all services and products that our clients receive.",
    "Подробнее о компании": "More about the company",
    "ЧТО МЫ ": "WHAT WE ",
    "ДЕЛАЕМ": "DO",
    'Компания готова к сотрудничеству как с крупными и малыми строительными организациями, так и с частными клиентами и заказчиками. Мы дорожим своим именем, а потому вся наша продукция производиться исключительно из надежных компонентов и проходит жесткий контроль качества на каждом этапе изготовления. Выпускаемые изделия, обеспечены всей необходимой документацией - сертификатами соответствия, пожарной безопасности и санитарно-эпидемиологическими заключениями.': 'The company is ready to cooperate with both large and small construction organizations, as well as with private clients and customers. We value our name, and therefore all our products are made exclusively from reliable components and undergo strict quality control at every stage of production. Manufactured products are provided with all necessary documentation - certificates of conformity, fire safety and sanitary and epidemiological conclusions.',
    "Производство полистиролбетона": "Production of polystyrene concrete",
    "Строительство малоэтажных домов": "Construction of low-rise buildings",
    "Подробнее об услугах": "More about the services",
    "Производство полистиролбетона (легкого бетона) - энергоэффективного материала, не имеющего аналогов на строительном рынке, наша специализация. Наша продукция реализуется под торговой маркой M1 GROUPE.": "The production of polystyrene concrete (lightweight concrete), an energy-efficient material that has no analogues in the construction market, is our specialization. Our products are sold under the M1 GROUPE brand.",
    "ПОЧЕМУ С НАМИ": "WHY YOU SHOULD",
    "СТОИТ РАБОТАТЬ": "WORK WITH US",
    "Производство": "Production",
    "услуги": "services",
    "Полистиролбетон обладает хорошей теплоизоляцией и легкостью, что делает его популярным материалом для строительства. На нашем производстве мы предлагаем высококачественный полистиролбетон, созданный с использованием передовых технологий и лучших материалов.": "Polystyrene concrete has good thermal insulation and lightness, which makes it a popular material for construction. In our production we offer high-quality polystyrene concrete, created using advanced technologies and the best materials.",
    "Если Вы хотите получить более подробную консультацию, рассчитать стоимость материалов и работ по монолитной заливки полистиролбетона M1 GROUPE, вы можете позвонить нам в офис по телефону, отправить запрос на почту или оставить заявку на нашем сайте. Наши специалисты с удовольствием ответят на Ваши вопросы, и окажут полное содействие в процессе выбора и согласования материала.": "If you want to get more detailed advice, calculate the cost of materials and work for monolithic pouring of M1 GROUPE polystyrene concrete, you can call our office by phone, send a request by mail or leave a request on our website. Our specialists will be happy to answer your questions and provide full assistance in the process of selecting and agreeing on the material.",
    "Консультация": "Consultation",
    "Область применения": "Application area",
    "Преимущества полистиролбетона M1 GROUPE ": "Advantages of polystyrene concrete of M1 GROUPE",
    "Как происходят работы по заливке? ": "How is the process of pouring polystyrene concrete",
    "полистиролбетона": "carried out",
    "Оказываем услуги по заливке и утеплению полистиролбетоном": "We provide services for pouring and insulating polystyrene concrete",
    "Строим быстровозводимые энергоэффективные дома": "We build prefabricated energy-efficient houses",
    "Производим современные строительные материалы из полистиролбетона высокого качества": "We produce modern building materials from high quality polystyrene concrete",
    "ПРОИЗВОДСТВО ПОЛИСТИРОЛБЕТОНА ИННОВАЦИОННОГО ЭНЕРГОЭФФЕКТИВНОГО": "PRODUCTION OF POLYSTYRENE CONCRETE INNOVATIVE ENERGY EFFICIENT",
    "ЛЕГКОГО БЕТОНА": "LIGHTWEIGHT CONCRETE",
    "Продукция нашей компании доставляется автомобильным транспортом по регионам Армении. Мы используем как собственный парк автотранспорта, так и сторонних проверенных грузоперевозчиков. Доставка осуществляется в течение 10 дней с момента оплаты товара, либо ранее, если товар имеется в наличии на складе.": "Our company's products are delivered by road to the regions Armenina. We use both our own fleet of vehicles and third-party proven cargo carriers. Delivery is carried out within 10 days from the date of payment for the goods, or earlier if the goods are in stock in the warehouse.",
    "Наличие товара на складе можно уточнить у менеджера компании, позвонив по телефону": "The availability of goods in the warehouse can be checked with the company manager by calling",
    "С каждой партией груза заказчику передаются накладная, паспорта качества и сертификаты на каждый товар.": "With each shipment, the customer is provided with an invoice, quality certificates and certificates for each product.",
    "Виды ": "Delivery",
    "доставки": "methods",
    "1. самовывоз": "1. pick-up",
    "Произведенную продукцию заказчик может забрать собственным автотранспортом со склада, который находится по адресу: РА, Араратский марз, с. Арарат, Арарат, 5 участок Ереванского шоссе производственная база M1 Groupe .Режим работы склада: ПН-ПТ с 9:00 до 18:00; СБ с 9:00 до 16:00 ВС – выходной.": "The customer can pick up the manufactured products using their own vehicles from our warehouse, which is located at RA, Ararat Marz, village Ararat, 5th plot of Yerevan highway M1 Groupe production base. Warehouse working hours:  Monday-Friday: 9:00  - 18:00; Saturday: 9:00  - 16:00; Sunday: day off.",
    "Водитель должен иметь при себе оригинал доверенности на получение груза. Заранее сообщите контактные данные водителя, марку и гос. номер автомашины, размер кузова и ее грузоподъемность": "To receive the cargo, the driver must present the original power of attorney. Please provide the driver's contact details, make and state license of the vehicle, body size, and load capacity.",
    "2. Доставка компанией": "2. Company delivery",
    "Доставка осуществляется по согласованному графику отгрузки товара, который подписывается сторонами и является неотъемлемой частью договора поставки или по отдельной согласованной заявке покупателя": "Delivery is conducted in accordance with the agreed schedule for shipping goods, which is signed by both parties and forms an integral part of the supply agreement or it may be conducted according to a separately agreed application by the buyer.",
    "При оформлении заказа следует указать точный адрес пункта доставки и телефон получателя груза.": "When placing an order, it is necessary to provide the exact address of the delivery point and the telephone number of the recipient.",
    "Товар загружается и отправляется со склада после того, как получатель товара подтвердит готовность его принять.": "The goods are loaded and dispatched from the warehouse once the recipient confirms their readiness to accept them.",
    "доставка": "delivery",
    "В день доставки приёмщику следует постоянно находиться на связи по указанному номеру телефона, чтобы диспетчер предварительно сообщил о расчётном времени прибытия машины. Если приемщик товара не доступен или не отвечает, дата поставки переносится на другое время.": "On the day of delivery, the recipient should remain reachable at the specified phone number. This allows the dispatcher to provide advance notice of the estimated arrival time of the vehicle. If the recipient is unavailable or does not respond, the delivery will be rescheduled for another time.",
    "Если территория, где находится пункт доставки, охраняется, заказчик должен оформить пропуск или принять товар перед въездом на территорию.": "If the delivery point is located in a guarded area, the customer must obtain a pass or accept the goods outside the guarded territory.",
    "Заказчику нужно обеспечить машине беспрепятственный подъезд к месту разгрузки (дорога должна иметь твердое покрытие шириной не менее 3 м, достаточную площадь с радиусом разворота не менее 15 м). Если требования не соблюдаются, товар будет доставлен максимально близко к месту планируемой выгрузки": "The customer must ensure unobstructed access for the vehicle to the unloading site (a road with a hard surface and a width of at least 3 meters, along with a sufficient area allowing for a turning radius of at least 15 m). Failure to meet these requirements may result in the goods being delivered as close as possible to the planned unloading location.",
    "При доставке груза манипулятором заказчик должен предоставить для разгрузки одного стропальщика.": "When delivering cargo by manipulator, the customer must provide one slinger to assist with unloading.",
    "Объемы загрузки ": "Truck loading",
    "грузового транспорта": "volume",
    "Тип автомобили": "Type of car",
    "Объем в машине (куб. м)": "Volume in the car (cubic meter)",
    "КЛЕЙ (шт.)": "GLUE (pcs.)",
    "СТЕНОВЫЕ БЛОКИ": "WALL BLOCKS",
    "ПЕРЕМЫЧКИ": "LINTELS",
    "Тарифы": "Delivery",
    " на доставку": "rates",
    "Стоимость доставки зависит от объема покупки и удаленности пункта выгрузки товара от склада, а также учитывает иные условия договора и рассчитывается менеджером нашей компании после обработки заявки.": "The cost of delivery is determined by factors such as the volume of purchase and the distance to the unloading point from the warehouse, as well as other terms specified in the contract. Our company's manager calculates the delivery cost after processing the application.",
    "Минимальная стоимость доставки – 60 000 драм при расстоянии до 10 км., свыше 100 км – 400 драм за км.": "Our minimum delivery cost is 60,000 drams for distances up to 10 km. For distances over 100 km, the cost is 400 dram per km",
    "Вся продукция M1 GROUPE перевозится на поддонах, упакованных в стрейч-пленку, которая защищает стройматериал от атмосферных осадков и механических повреждений во время погрузки, транспортировки и разгрузки. Все паллеты жестко фиксируются в кузове при помощи специальных приспособлений.": "All M1 GROUPE products are transported on pallets wrapped in stretch film, providing protection from precipitation and mechanical damage during loading, transportation, and unloading. The pallets are securely fixed in the body using special devices.",
    "Расстояние от склада": "Distance from warehouse",
    "20 тонн": "20 tons",
    "До 10 км": "Up to 10 km",
    "До 40 км": "Up to 40 km",
    "До 100 км": "Up to 100 km",
    "Свыше 100 км": "Over 100 km",
    "+ 400 драм. за км": "+ 400 dram per km",
    "о кОМПАНИИ": "About company",
    "СТРОИТЕЛЬНЫЕ КОНСТРУКЦИИ - команда профессионалов с многолетним опытом работы в строительной отрасли. Мы занимаемся производством и реализацией строительных материалов на территории РФ. Основным направлением деятельности нашей компании является производство инновационного, энергоэффективного строительного материала, полистиролбетона, не имеющего аналогов в современном строительстве.": "BUILDING CONSTRUCTIONS is a team of experienced professionals in the construction industry, specializing in the production and sale of building materials in the Russian Federation. Our main focus is on producing innovative and energy-efficient building material, polystyrene concrete, which is unmatched in modern construction.",
    "Наша продукция изготавливается в строгом соответствии со стандартами ГОСТ 33929-2016 на современном технологичном оборудовании, что позволяет нам быть лидерами на этом направлении, а нашей торговой марке M1 GROUPE - знаком качества продукции из полистиролбетона.'": "Our products are manufactured in strict accordance with GOST 33929-2016 standards, utilizing modern technological equipment, which allows us to maintain leadership in this industry and our trademark, M1 GROUPE, is a symbol of quality for polystyrene concrete products.",
    "Наши": "Our",
    "цели": "goals",
    "Компания СТРОИТЕЛЬНЫЕ КОНСТРУКЦИИ - команда профессионалов с многолетним опытом работы в строительной отрасли. Мы занимаемся производством и реализацией строительных материалов на территории РФ. Основным направлением деятельности нашей компании является производство инновационного, энергоэффективного строительного материала, полистиролбетона, не имеющего аналогов в современном строительстве.": "BUILDING CONSTRUCTIONS company is a team of experienced professionals in the construction industry, specializing in the production and sale of building materials in the Russian Federation. Our main focus is on producing innovative and energy-efficient building material, polystyrene concrete, which is unmatched in modern construction",
    "Долгое время мы внимательно изучали рынок и следили за передовыми тенденциями в сфере производства строительных материалов. Использование изделий из полистиролбетона выделяется как одно из наиболее перспективных направлений в современном строительстве.": "After extensive market research and monitoring of industry trends, we have identified the use of polystyrene concrete products as one of the most promising areas in modern construction.",
    "Однако практика показывает, что и строительный комплекс, и частные заказчики недостаточно знают о бесспорных преимуществах и экономической выгоде применения этой технологии. В результате отдают предпочтение более разрекламированному материалу который по ключевым характеристикам значительно уступает полистиролбетону.": "Despite its undeniable advantages and economic benefits, polystyrene concrete technology remains relatively unknown among both construction companies and private customers. As a result, more advertised materials, despite their inferiority in key characteristics, are often preferred.",
    "Но мы уверены в перспективах этого направления и выбрали иной подход к решению данной проблемы. Так появился наш проект, цель которого — производство, продвижение и реализация продукции из полистиролбетона под торговой маркой M1 GROUPE": "We are confident in the potential of polystyrene concrete technology and have adopted a unique approach to tackle the issue. This led to the inception of our project, which aims to produce, promote, and sell polystyrene concrete products under the M1 GROUPE brand.",
    "Наш": "Our",
    "подход": "approach",
    "Мы уверены, что единственный путь к успешному развитию бизнеса — это предлагать продукцию и услуги, которые полностью отвечает требованиям заказчиков.": "We are confident that the only way to successful business development is to offer products and services that fully meet customer requirements.",
    "Главное преимущество продукции iQ-Beton™ — высокий уровень контроля качества на каждом этапе производства: при выборе и закупке сырья и материалов, ведении технологических процессов со строгим соблюдением всех норм, при упаковке и доставке продукции на строительный объект": "The primary advantage of iQ-Beton™ products is the meticulous quality control maintained throughout every stage of production: careful selection and procurement of raw materials, adherence to strict technological processes in compliance with all standards, and meticulous packaging and delivery to the construction site.",
    "В сфере услуг наша компания предлагает качественный и надежный сервис. Мы ответственно подходим к выполнению всех стадий оказания услуг, учитывая запросы и пожелания наших заказчиков.": "In the service sector, our company provides high-quality and dependable services. We approach each stage of service provision with responsibility, carefully considering the requests and preferences of our customers.",
    "Доверяя нам, вы выбираете качественное и эффективное решение своих строительных задач!": "By entrusting us, you opt for high-quality and efficient solutions to your construction challenges!",
    "партнеры": "partners",
    "Наша продукция изготавливается в строгом соответствии со стандартами ГОСТ 33929-2016 на современном технологичном оборудовании, что позволяет нам быть лидерами на этом направлении, а нашей торговой марке M1 GROUPE - знаком качества продукции из полистиролбетона.": "Our products are manufactured in strict accordance with GOST 33929-2016 standards, utilizing modern technological equipment, which allows us to maintain leadership in this industry and our trademark, M1 GROUPE, is a symbol of quality for polystyrene concrete products.",
    "Стратегической целью компании является популяризация полистиролбетона и вывод его на качественно иной, высокий уровень конкурентоспособности среди аналогичных материалов": "Our strategic goal is to popularize polystyrene concrete and elevate its competitiveness to a higher level among similar materials.",
    "Работаем только с надежными поставщиками": "We exclusively collaborate with reliable suppliers.",
    "Продукция нашей компании доставляется автомобильным транспортом по регионам ЦФО, доставка в другие регионы РФ оговаривается отдельно. Мы используем как собственный парк автотранспорта, так и сторонних проверенных грузоперевозчиков. Доставка осуществляется в течение 10 дней с момента оплаты товара, либо ранее, если товар имеется в наличии на складе.Стеновые блоки из полистиролбетона M1 GROUPE — современный технологичный материал, соответствующий требованиям ГОСТ 33929 - 2016, который позволяет строить теплые и надежные здания любого назначения и сочетают в себе достоинства бетона по прочности и пенополистирола по тепло - и звукоизоляции.": "Our company's products are delivered by road to the regions of the CFD, delivery to other regions of the Russian Federation is negotiated separately.We use both our own fleet of vehicles and third-party proven cargo carriers. Delivery is carried out within 10 days from the date of payment for the goods, or earlier if the goods are in stock in the warehouse. Wall blocks made of polystyrene concrete of M1 GROUPE are a modern technological material that complies with the requirements of GOST 33929-2016. They enable the construction of warm and reliable buildings for various purposes, combining the strength of concrete with the heat and sound insulation properties of polystyrene foam.",
    "Блоки M1 GROUPE имеют низкое водопоглощение, легкий вес и при этом высокую прочность на сжатие и на растяжение при изгибе, что делает их прочными и долговечными. Их использование увеличивает скорость работы и снижает стоимость строительства. Применение этого материала в 2-3 раза эффективнее и выгоднее по сравнению с пенобетоном, газобетоном и другими видами легкого бетона.": "M1 GROUPE blocks feature low water absorption, lightweight construction, and high compressive and tensile strength, making them exceptionally strong and durable. Their utilization accelerates construction progress and reduces overall costs. Compared to foam concrete, aerated concrete, and other lightweight concrete options, the use of M1 GROUPE material is 2-3 times more effective and cost-efficient.",
    "Блоки M1 GROUPE применяют для возведения наружных самонесущих оградительных конструкций с опиранием на несущий монолитный каркас зданий до 25 этажей, а также возведения наружных несущих стен и внутренних перегородок в малоэтажном строительстве.": "M1 GROUPE blocks are utilized in the construction for self-supporting enclosing structures, which are supported by a load-bearing monolithic frame of buildings up to 25 floors, as well as for external load-bearing walls and internal partitions in low-rise construction.",
    "Наша компания производит стеновые блоки из полистиролбетона широкого диапазона плотностей и размерного ряда.": "Our company manufactures wall blocks from polystyrene concrete in a wide range of densities and sizes.",
    "Блоки из полистиролбетона m1 gROUPE": "Polystyrene concrete blocks - M1 GROUPE",
    "Вид полистиролбетона": "Type of polystyrene concrete",
    "Размеры блоков": "Block sizes",
    "Плотность (D) кг/м3": "Density (D) kg/m3",
    "Класс (марка) прочности": "Strength class (grade)",
    "Тепло- проводность λ, Вт/(м-°С)": "Thermal conductivity λ, W/(m-°C)",
    "Тип автомобилиПаропро- ницаемость µ, мг/(м*ч*Па)": "Type of car  Vapor permeability µ, mg/(m*h*Pa)",
    "Морозо- стойкость (F)": "Frost resistance (F)",
    "Группа горючести": "Flammability group",
    "Преимущества": "Advantages",
    "Стеновые блоки из полистиролбетона M1 GROUPE по многим параметрам эффективнее других строительных материалов": "Wall blocks made of polystyrene concrete of M1 GROUPE offer superior effectiveness in many aspects compared to other building materials.",
    "Основные характеристикиполистиролбетонных ": "Main characteristics of polystyrene concrete",
    "блоков M1 GROUPE": "blocks of M1 GROUP",
    "Строим комбинированные быстровозводимые энергоэффективные, современные ЭКО дома 21 века.": "We construct modern, energy-efficient ECO houses using combined prefabrication techniques, tailored for the 21st century.",
    "В активе компании — собственный производственный цех 1000 кв.м и складская территория 13000м2 расположенная в РА, Араратский марз, село Арарат, Арарат, 5 участок Ереванского шоссе.": "The company operates its own 1000 sq.m production workshop and a 13000 sq.m warehouse located at Yerevanyan Highway, plot 5, v. Ararat, Ararat, Ararat Province, RA.",
    "Высокого качества по доступной цене!": "High-quality products at competitive prices.",
    "Продукция нашей компании доставляется автомобильным транспортом по регионам Армении. Мы используем как собственный парк автотранспорта, так и сторонних проверенных грузоперевозчиков. Доставка осуществляется в течение 10 дней с момента оплаты товара, либо ранее, если товар имеется в наличии на складе.Стеновые блоки из полистиролбетона M1 GROUPE — современный технологичный материал, соответствующий требованиям ГОСТ 33929 - 2016, который позволяет строить теплые и надежные здания любого назначения и сочетают в себе достоинства бетона по прочности и пенополистирола по тепло - и звукоизоляции.": "Our company's products are delivered by road to the regions of Armenia. We use both our own fleet of vehicles and third-party proven cargo carriers. Delivery is carried out within 10 days from the date of payment for the goods, or earlier if the goods are in stock in the warehouse. Wall blocks made of polystyrene concrete of M1 GROUPE are a modern technological material that complies with the requirements of GOST 33929-2016. They enable the construction of warm and reliable buildings for various purposes, combining the strength of concrete with the heat and sound insulation properties of polystyrene foam.",
    "Отправить": "Send",
    "Главное преимущество продукции M1 Group — высокий уровень контроля качества на каждом этапе производства: при выборе и закупке сырья и материалов, ведении технологических процессов со строгим соблюдением всех норм, при упаковке и доставке продукции на строительный объект": "The primary advantage of M1 Group products is the meticulous quality control maintained throughout every stage of production: careful selection and procurement of raw materials, adherence to strict technological processes in compliance with all standards, and meticulous packaging and delivery to the construction site.",
    "Компания M1 Group - команда профессионалов с многолетним опытом работы в строительной отрасли. Мы занимаемся производством и реализацией строительных материалов на территории РФ и Армении. Основным направлением деятельности нашей компании является производство инновационного, энергоэффективного строительного материала, полистиролбетона, не имеющего аналогов в современном строительстве.": "M1 Group company is a team of experienced professionals in the construction industry, specializing in the production and sale of building materials in the territory of the Russian Federation and Armenia. Our main focus is on producing innovative and energy-efficient building material, polystyrene concrete, which is unmatched in modern construction.",
    "При покупке блоков в заказ необходимо включить поддоны.  Блоки продаются  кратно поддонам, наша доставка осуществляется только целыми поддонами. Стоимость поддонов возвращается при их сдаче в течение 3 месяцев.": "Pallets are included with block purchases, with delivery available only in whole pallet quantities. The cost of pallets is refundable upon return within 3 months.",
    "Если количество блоков не кратно поддонам, то погрузчик не сможет осуществить загрузку, поэтому блоки загружается клиентом самостоятельно.": "If the number of blocks is not a multiple of pallets, customers are responsible for loading the blocks themselves, as forklift loading is only available for full pallet quantities.",
    "Важная информация!": "Important information!",
    "Мы являемся 1-м заводом производителем полистиролбетонных блоков в Армении.": "We are the first plant in Armenia to produce polystyrene concrete blocks.",
    "ПОЛИСТИРОЛБЕТОН ЭТО:": "POLYSTYRENE CONCRETE:",
    "Заборы из Полистиролбетона": "Fences made of Polystyrene concrete",
    "Теплоизоляционные Плитки": "Thermal insulation tiles",
    "Фасадные Блоки": "Facade Blocks",
    "Стеновые Блоки": "Wall Blocks",
    "Вибропрессованные блоки": "Vibration-pressed blocks",
    "Полистиролбетон применяется в ограждающих конструкциях каркасных зданий, несущих стенах и перегородках, как утеплитель стен, полов, чердаков, кровли, используется для производства строительных блоков и фасадных декоративных утеплительных панелей, жидкого раствора для заливки монолита. Полистиролбетон обладает хорошей конструкционной прочностью.": "Polystyrene concrete is used in the enclosing structures of frame buildings, load-bearing walls and partitions. It serves as effective insulation for walls, floors, attics, and roofs. Additionally, it is utilized in the production of building blocks, facade decorative insulation panels, and liquid mortar for pouring monoliths. Polystyrene concrete exhibits commendable structural strength.",
    "Экономичность Комфорт технологичность Безопасность Прочность долговечность Биостойкость Огнестойкость легкость Влагостойкость Трещиностойкость Звукопоглощение Энергоэффективность": "Cost-effectiveness <br> Comfortability <br> Manufacturability <br> Safety <br> Strength <br> durability <br> Biostability <br> Fire resistance <br> Lightness <br> Moisture resistance <br> Crack resistance <br> Sound absorption <br> Energy efficiency",
    "В нашей компании работает команда профессионалов, которая с 2004 года успешно реализовала множество проектов в сфере строительства и производства строительных материалов. Все сотрудники прошли специальную подготовку и постоянно повышают уровень своей квалификации.Мы знаем все потребности и проблемы рынка и стараемся, чтобы каждый наш клиент остался доволен как товаром, так и предоставляемым сервисом. Наш опыт и качество производимой продукции — залог вашего успеха!": "In our company, a team of professionals has been successfully implementing numerous projects in the field of construction and production of building materials since 2004. All employees have undergone special training and constantly improve their qualifications. We are aware of all the needs and problems of the market and strive for each of our clients to be satisfied with both the product and the service provided. Our experience and the quality of the products we produce are the key to your success!",
    "Полистиролбетон идеальный материал для климата в Армении, он не пропускает тепло, зимой в доме будет тепло, а летом прохладно. Колоссальная экономия Газа на отоплении, а летом электроэнергии на кондиционере.": "Polystyrene concrete is an ideal material for the climate in Armenia; it does not let heat pass through, keeping the house warm in winter and cool in summer. It provides significant savings on heating costs in winter and electricity for air conditioning in summer.",
    "Энергоэффективность": "Energy efficiency",
    "Материал с высоким уровнем шумопоглощения. Для тех, кто любит тишину и покой.": "A material with a high level of sound absorption. For those who love peace and quiet.",
    "Звукопоглощение": "Sound absorption",
    "Позволяет экономить на количестве материала, стоимости работ для возведения стен, не требует утепления, экономия на стоимости различных работ. ": "Allows saving on the amount of material, the cost of wall construction, does not require insulation, saving on the cost of various works.",
    "Экономичность": "Economy",
    "Полистиролбетон выдерживает до 200 циклов замораживания и размораживания": "Polystyrene concrete withstands up to 200 freeze-thaw cycles",
    "Долговечность": "Durability",
    "Способствует минимизации трещин за счет прочности на растяжение при изгибе.": "Contributes to minimizing cracks due to tensile strength during bending.",
    "Трещиностойкость": "Crack resistance",
    "Полистиролбетон практически не впитывает влагу, что позволяет отложить устройство фасада, а это значит материал сохраняет свои теплоизоляционные свойства при намокании (в отличии от газоблока!), не требует устройства дорогостоящей защиты фасада, препятствует возникновению плесени.": "Polystyrene concrete practically does not absorb moisture, allowing for the delayed installation of the facade, meaning the material retains its thermal insulation properties when wet (unlike gas blocks!), does not require the installation of expensive facade protection, prevents the occurrence of mold.",
    "Влагостойкость": "Water resistance",
    "Благодаря специальной технологии полистиролбетон не горит (НГ) и не поддерживает горение.": "Thanks to a special technology, polystyrene concrete does not burn (NG) and does not support combustion.",
    "Огнестойкость": "Fire resistance",
    "не способствует образованию плесени и грибка, не повреждается грызунами и насекомыми.": "Does not promote the formation of mold and mildew, is not damaged by rodents and insects.",
    "Биостойкость": "Biostability",
    "Мы дорожим своими клиентами и своей репутацией. Поэтому предлагаем только материалы высокого качества.Вся продукция под маркой M1 GROUPE производится исключительно из качественного первичного сырья ведущих производителей отрасли и изготавливается на современных автоматизированных производственных линиях. Материалы сертифицированы и обеспечены необходимой документацией.": "We value our customers and our reputation. That's why we offer only high-quality materials. All products under the M1 GROUPE brand are made exclusively from high-quality primary raw materials from leading industry manufacturers and are manufactured on modern automated production lines. The materials are certified and provided with the necessary documentation.",
    "Исключительное качество": "Exceptional quality",
    "Наша цель — ваш правильный выбор.Мы стремимся дать максимум информации о продукте, подобрать оптимальные решения под ваши задачи и оказать полное содействие в их согласовании на любом уровне. Ваш персональный менеджер на связи 24/7.": "Our goal is your right choice. We strive to provide maximum information about the product, select optimal solutions for your tasks, and provide full assistance in their coordination at any level. Your personal manager is available 24/7.",
    "Индивидуальный подход": "Individual approach",
    "Для постоянных клиентов действуют индивидуальные условия сотрудничества:Программа «Рекомендация» — бонусы за каждого привлеченного клиентаСпециальные акции, бонусы, скидки, подарки.Мы ценим своих клиентов и с удовольствием находим, чем их порадовать.": "For regular customers, individual cooperation conditions apply: Recommendation program - bonuses for each attracted client Special offers, bonuses, discounts, gifts. We value our customers and are happy to find ways to please them.",
    "Программа лояльности": "Loyalty program",
    "Наш принцип — работать честно и на совесть. Это не просто слова: мы предоставляем нашим клиентам полную информацию о заказе и проделанной работе, поставщиках сырья и применяемых материалов, вовремя предоставляем все отчетные документы.": "Our principle is to work honestly and conscientiously. These are not just words: we provide our customers with complete information about the order and work done, suppliers of raw materials and materials used, and timely provide all reporting documents.",
    "Нам можно доверять": "You can trust us",
    "В активе компании — собственный производственный цех 1000 кв.м и складская территория 13000м2 расположенная  в  РА, Араратский марз, село Арарат, Арарат, 5 участок Ереванского шоссе.": "The company's assets include its own production workshop of 1000 sq.m and a warehouse area of 13000 sq.m located in RA, Ararat marz, village Ararat, Ararat, 5th plot of Yerevan highway.",
    "Собственное производство": "Own production",
    "Знаем свое дело": "We know our business",
    "Срок службы не менее 150 лет.": "Service life of at least 150 years.",
    "Хорошо держат линейные нагрузки за счет высокой прочности на сжатие и растяжение при изгибе, не разрушаются при перевозке и обладают высокой трещиностойкостью.": "They withstand linear loads well due to high strength under compression and tension during bending, do not break during transportation, and have high crack resistance.",
    "Подходят для возведения несущих конструкций": "Suitable for the construction of load-bearing structures",
    "Прочность": "Strength",
    "РА, Араратский марз, с. Арарат, Арарат, 5 участок Ереванского шоссе.": "RA, Ararat Marz, village Ararat, Ararat, 5th plot of Yerevan highway.",
    "Экологическая чистота — используется пищевой полистирол, за счёт многократного вспенивания не содержит вредных смол.": "Environmental cleanliness - food-grade polystyrene is used, thanks to repeated foaming, it does not contain harmful resins.",
    "Сейсмоустойчивость — от 9 до 12 баллов": "Seismic resistance - from 9 to 12 points",
    "Пожаробезопасность — негорючий материал, 1 категория огнестойкости": "Fire safety - non-combustible material, 1st category of fire resistance",
    "Безопасность": "Safety",
    "Удобство обработки — легко сверлить, пилить, можно придать любую форму.": "Ease of processing - easy to drill, saw, can be given any shape.",
    "Малый вес — упрощает транспортировку, не нагружает фундамент, дом не даёт усадку.": "Lightweight - simplifies transportation, does not load the foundation, the house does not shrink.",
    "Точность размеров блоков — погрешность не более 2-3 мм": "Accuracy of block sizes - error not more than 2-3 mm",
    "Технологичность": "Technological efficiency",
    "Не гниет, не подвержен образованию плесени и бактерий": "Does not rot, is not susceptible to mold and bacteria formation",
    "Морозостойкость — выдерживает колебания температур от -40 °С до +75 °С": "Frost resistance - withstands temperature fluctuations from -40°C to +75°C",
    "Паропроницаемость — стены дышат, регулируя влажность в помещении": "Vapor permeability - walls breathe, regulating humidity in the room",
    "Влагостойкость — в 5 раз выше, чем у газоблоков": "Moisture resistance - 5 times higher than that of gas blocks",
    "Отличная теплоизоляция и теплосбережение - зимой при -20С дом теряет 1С за сутки": "Excellent thermal insulation and heat preservation - in winter at -20°C, the house loses 1°C per day",
    "Комфорт": "Comfort",
    "Увеличение внутренней полезной площади помещений здания - как дополнительный приятный бонус.": "Increased useful internal area of the building premises - as an additional pleasant bonus.",
    "Сокращение расходов на приобретение и монтаж дополнительного утеплителя и на оплату энергоресурсов при дальнейшей эксплуатации объекта.": "Reduced costs for the purchase and installation of additional insulation and for the payment of energy resources during further operation of the facility.",
    "Экономия расхода раствора до 70% за счет точности геометрических размеров и сокращения толщины стены": "Saving mortar consumption by up to 70% due to the accuracy of geometric dimensions and thickness reduction of the wall",
    "Снижение трудозатрат за счет малого веса в 1,5 раза": "Reduced labor costs due to a weight reduction of 1.5 times",
    "Снижение стоимости 1 кв. м стены за счет материалоемкости в 1,5 - 2 раза": "Reduced cost per square meter of wall due to material consumption by 1.5 - 2 times",
    "Высокая звукоизоляция — блок до 20 см гасит звук до 70 д": "High sound insulation - block up to 20 cm extinguishes sound up to 70 dB",
    "Добавить В Корзину": "Add To Cart",
    "Корзина": "Basket",
    "Заказать Сейчас": "Order Now",
    "ваше сообщение успешно было отправлено": "your message has been sent successfully",
    "Please input your initials": "Please input your initials",
    "Please input a correct phone number": "Please input a correct phone number",
    "Please input phone number": "Please input phone number",
    "Please input a correct email address": "Please input a correct email address",
    "Please input email address": "Please input email address",
    "ФИО*": "Initials*",
    "E-mail*": "E-mail*",
    "Phone Number*": "Phone Number*",

}